define("frontend/pods/agent-transfer-reports/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AgentTransferReportsRoute = Ember.Route.extend({
    requiresLogin: true,
    authorizedRoles: ["admin"],
    // ---------------------------------------------------------------------------
    // Method to return the first record inside the store requested from the API.
    // ---------------------------------------------------------------------------
    model: function model() {
      return this.store.findAll("agent-transfer-report").then(function (reports) {
        if (reports.get("length") > 0) {
          var firstReport = reports.get("firstObject");
          /* I Leave this two lines only for debugging in case of any error.*/
          // console.log("ID of the first report:", firstReport.get("id"));
          // console.log("All reports:", reports.toArray());
        } else {
          console.log("No reports.");
        }

        return reports;
      }).catch(function (error) {
        console.error("Error loading reports:", error);
        return [];
      });
    }
  });
  var _default = AgentTransferReportsRoute;
  _exports.default = _default;
});