define("frontend/models/agent-transfer-report", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    role: (0, _model.attr)("string"),
    userId: (0, _model.attr)("string"),
    message: (0, _model.attr)("string"),
    usersReport: (0, _model.attr)(),
    agentCallResults: (0, _model.attr)(),
    agentReportsResults: (0, _model.attr)(),
    idAgent: (0, _model.attr)("string"),
    pagination: (0, _model.attr)("number"),
    uniqueIdentifier: (0, _model.attr)("string"),
    dialType: (0, _model.attr)("string"),
    viewParam: (0, _model.attr)("string"),
    // query by date range
    startTime: (0, _model.attr)("date"),
    endTime: (0, _model.attr)("date"),
    save: function save() {
      // Limpiar datos anteriores antes de guardar
      this.set("usersReport", null);
      this.set("agentCallResults", null);
      this.set("agentReportsResults", null);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});