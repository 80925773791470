define("frontend/pods/agent-transfer-reports/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "widget-agent-big-graph": "_widget-agent-big-graph_ndgq85",
    "graph-agent-big-container": "_graph-agent-big-container_ndgq85",
    "graph-agent-big-widget-body": "_graph-agent-big-widget-body_ndgq85",
    "graph-agent-title": "_graph-agent-title_ndgq85",
    "widget-agent-small-graph": "_widget-agent-small-graph_ndgq85",
    "graph-agent-small-container": "_graph-agent-small-container_ndgq85",
    "graph-agent-small-widget-body": "_graph-agent-small-widget-body_ndgq85",
    "graph-agent-grid": "_graph-agent-grid_ndgq85",
    "tableContainer-agent": "_tableContainer-agent_ndgq85",
    "tableHeader-agent": "_tableHeader-agent_ndgq85",
    "tableCell-agent": "_tableCell-agent_ndgq85",
    "tableBody-agent": "_tableBody-agent_ndgq85",
    "tableRow-agent": "_tableRow-agent_ndgq85",
    "table-agent-container": "_table-agent-container_ndgq85",
    "c3-chart-texts": "_c3-chart-texts_ndgq85",
    "c3-text": "_c3-text_ndgq85",
    "tabs": "_tabs_ndgq85",
    "active-tab": "_active-tab_ndgq85",
    "tab-content": "_tab-content_ndgq85",
    "info-box-overlay": "_info-box-overlay_ndgq85",
    "info-box-content": "_info-box-content_ndgq85",
    "close-button-info-box": "_close-button-info-box_ndgq85",
    "user-list": "_user-list_ndgq85",
    "tableContainer-user": "_tableContainer-user_ndgq85",
    "tableHeader-user": "_tableHeader-user_ndgq85",
    "tableBody-user": "_tableBody-user_ndgq85"
  };
  _exports.default = _default;
});